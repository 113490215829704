<template>
<div class="container">

	<section id="header">

		<h1 class="text-center">AlphaBatem Labs</h1>

	</section>
	<section id="products">

		<h3 class="text-center mb-5">Our Products & Services</h3>

		<div class="row">
			<a :href="product.uri" target="_blank" class="col-12 col-md-6 col-lg-4 col-xl-4 mb-4" v-for="(product,key) in products" :key="key">
				<div class="card">
					<div class="card-header text-center">
						<h2>{{product.name}}</h2>
					</div>
					<div class="card-body text-center">
						<img loading="lazy" alt="" onload="this.style.opacity=1" style="height: 128px" class="img-fluid fade-in" :title="product.name" :src="product.image">
					</div>
<!--					<div class="card-footer">-->
<!--						<p>{{product.description}}</p>-->
<!--					</div>-->
				</div>
			</a>
		</div>

	</section>

</div>
</template>

<script>
export default {
	name: "HomeNext",
	data() {
		return {
			products: [
				{name: "BlokHost", description: "", image: "/images/social/blokhost.png", uri: 'https://blok.host'},
				{name: "RugCheck", description: "", image: "/images/social/rugcheck.png", uri: 'https://rugcheck.xyz'},
				{name: "FluxBeam", description: "", image: "/images/social/fluxbeam.png", uri: 'https://fluxbeam.xyz'},
				{name: "FluxBot", description: "", image: "/images/social/fluxbeam.png", uri: 'https://fluxbot.xyz'},
				{name: "Flux RPC", description: "", image: "/images/social/fluxbeam.png", uri: 'https://fluxbot.xyz'},
				{name: "DegenCDN", description: "", image: "/images/social/degencdn.png", uri: 'https://degencdn.com'},
			]
		}
	}
}
</script>

<style scoped>
.card {
	background: black;
	border: 1px solid white;
}

.card-body {
	background: black;
}

h1,h2,h3 {
	font-family: monospace;
	text-transform: uppercase;
}

.card:hover {
	transform: scale(1.05);
}

img {
	opacity: 0;
}

/* Base fade-in animation */
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Apply to images */
.fade-in {
	opacity: 0; /* Start with invisible image */
	animation: fadeIn 1s ease-in forwards; /* 1 second animation that keeps final state */
}
</style>